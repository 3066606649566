/******************************

General

*****************************/
.common_color {
    color: #0a1612;
    color: #f3d250;
    color: #BAC0CF;
    color: #6fd1cc;
    color: #dddddd;
    color: #ffccbc;
    box-shadow: 1px 2px 2px rgba(146, 146, 146, 0.357);
    box-shadow: 5px 5px 25px rgba(49, 47, 47, 0.357);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    -webkit-box-sizing: border-box;
    transition: all ease .25s;
    /* font-family: monospace; */
    font-family: sans-serif;
    font-size: 16px;
}
body{
    background-color: #dfdfdf;
    margin-left: 220px;
}
.wrapper {
    width: calc(100% - 40px);
    max-width: 1400px;
    margin: auto;
}
.container {
    width: calc(100% - 20px);
    margin: 10px;
}
a {
    color: #0862b8;
    /* text-decoration: none; */
}
.active, 
.active:hover {
    color: #fff !important;
    font-weight: bold;
}

.clearfix:after {
    display: block;
    content: '';
    clear:both;
}
.hidden {
    display: none;
}
.btn{
    background: #ffd415;
    box-shadow: 1px 2px 2px rgba(49,47,47,.36);
    cursor: pointer;
    border-radius: 2px;
    font-weight: bold;
    margin: 7px 0;
    text-align: center;
    padding: 10px 7px;
    border: none;
    display: inline-block;
}
.btn:hover {
    background: #b4b4b4;
    box-shadow: 1px 2px 2px #9292925b;
    font-weight: bold;
}


input{
    margin: 15px 0;
    padding: 5px 10px;
    width: 100%;
    max-width: 300px;
}
input[type="file"]{
    background: #fff;
    box-shadow: 1px 2px 2px rgba(49,47,47,.36);
    cursor: pointer;
    border-radius: 2px;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
}
input[type="file"]:hover {
    background: #b4b4b4;
    box-shadow: 1px 2px 2px #9292925b;
    font-weight: bold;
}

/*************************
        MODALS
************************/
.modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(16, 15, 15, 0.76);
    transition: all .5s;
}
.modal_content {
    position: relative;
    padding: 2em 3em;
    border-radius: 2px;
    background: #fff;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 100%;
    max-width: 500px;
    text-align: center;
}
.modal_close{
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
}


/*************************
        HEADER
************************/
header {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 200px;
    background: #232B2D;
    transition: all .25s;
    z-index: 99;
}

header ul{
    margin: 0;
}
header li{
    list-style: none;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
header a{
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: rgb(147, 147, 147);
    padding: .5em 1.5em;
}
.menu {
    display: none;
}
header .navigation {
    margin-top: 0;
}


/*************************
        LOGIN
************************/
.login{
    width: calc(100% - 40px);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: -55px;
}
.login form{
    display: flex;
    flex-direction: column;
}

/*************************
        ALBUMS
************************/

.albums .content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.albums .item,
.albums .itemPhoto{
    width: 290px;
    margin: 0 15px 15px 0;
    /* background: #fff;
    box-shadow: 1px 2px 2px rgba(49,47,47,.16);
    border-radius: 3px; */
    padding: 5px 5px 15px;
    cursor: pointer;
}
.albums .bg{
    width: calc(100% - 10px);
    margin: 5px;
    height: 250px;
    background-size: cover;
    background-position: center;
    position: relative;
}
.albums .bg.section{
    width: calc(100% - 10px);
    margin: 5px;
    height: 250px;
    background-size: cover;
    background-position: center;
    position: relative;
    background: #cacaca;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    color: #3a3a3a;
    font-weight: 600;
}
.albums h4.title{
    text-transform: capitalize;
    margin-left: 5px;
    margin-top: 15px;
    color: rgb(109, 109, 109);
}
/* .albums .item:hover .bg,
.albums .item:hover .bg.add{
    filter: brightness(.6);
} */
.albums .item:hover{
    background: #fff;
    box-shadow: 1px 2px 2px rgba(49,47,47,.16);
    border-radius: 3px;
}

.albums .item:hover h4.title{
    color: #0a1612;
}
.albums .bg.add{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #BAC0CF;
    border-radius: 2px;
}
.albums a{
    text-decoration: none;
}
.albums .heading h1{
    font-size: 2.2rem;
    margin-bottom: 15px;
}
.albums hr{
    margin-bottom: 15px;
    height: 3px;
    background: #000;
}


.update_form{
    padding: 2em;
    background: #fff;
    position: relative;
}


/* MultiFilesUpload */
.btn_trash {
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: all 0s;
    text-shadow: 1px 1px 9px #000, 1px 1px 9px #000;
    padding: 10px;
    border-radius: 50%;
}
.btn_trash:hover{
    color: grey;
}

.btn_group {
    display: flex;
    justify-content: space-between;
    width: 400px;
    float: right;
}
.btn_delete_all {
    padding: .5em 1em;
}


/* Layout page */
.layout {
    position: relative;
}
.layout .container{
    width: 100%;
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: center;
}
.layout .col_lg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    margin: 0 20px;
    flex-wrap: wrap;
}
.layout .col_sm {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin: 0 20px;
}
.layout .frame {
    background-size: cover;
    background-position: center;
    width: 100%;
    cursor: pointer;
    border: 4px solid #fff;
}
.layout .frame:hover {
    filter: brightness(90%);
}
.layout .col_lg .frame{
    height: 250px;
    margin: 20px 20px;
    width: 40%;
}
.layout .col_sm .frame{
    height: 230px;
    margin: 20px 0;
}
.layout .modal{
    display: block;
    overflow: auto;
}
.layout .modal .container{
    flex-wrap: wrap;
}
.layout .modal .item{
    width: 30%;
}
.layout .modal_content{
   background: #d3d3d3;
   max-width: calc(100% - 255px);
}

.layout .item,
.layout .itemPhoto{
    width: 321px;
    margin: 0 15px 15px 0;
    background: #fff;
    box-shadow: 1px 2px 2px rgba(49,47,47,.16);
    border-radius: 3px;
    padding: 5px 5px 15px;
    cursor: pointer;
}
.layout .bg{
    width: calc(100% - 10px);
    margin: 5px;
    height: 160px;
    background-size: cover;
    background-position: center;
    position: relative;
}
.layout h4.title{
    text-transform: capitalize;
    margin-left: 5px;
    margin-top: 15px;
    color: rgb(109, 109, 109);
}
.layout .item:hover .bg,
.layout .item:hover .bg.add{
    filter: brightness(.6);
}
.layout .item:hover h4.title{
    color: #0a1612;
}
.layout button {
    margin: 20px;
    float: right;
    padding: .5em 2em;
}


.create .bg.section{
    width: calc(100% - 10px);
    max-width: 400px;
    margin: 5px;
    height: 250px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
}
input, textarea{
    width: 100%;
    max-width: 400px;
    padding: 10px 5px;
    margin: 5px 0;
}

@media (max-width: 769px){
    .menu{
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        transform: scale(1.5);
        z-index: 100;
    }
    header.slideOut{
        top: 0px;
    }
    header .navigation{
        margin-top: 50px;
    }
    header {
        top: -100vh;
        width: 100%;
    }
    header a {
        font-size: 1.8rem;
    }

    body{
        margin-left: 0px;
        margin-top: 55px;
    }
    .albums .content{
        justify-content: space-between;
    }
    .albums .item,
    .albums .itemPhoto {
        width: 49%;
        margin: 5px 0;
    }
    .albums .bg{
        height: 115px;
    }
    .albums .bg.section{
        height: 115px;
    }
    .create .bg.section{
        height: 115px;
    }
    .btn_group{
        width: 100%;
    }
    .btn_delete_all{
        padding: .2em;
    }
    input, textarea{
        max-width: 100%;
        padding: 10px 5px;
        margin: 5px 0;
    }
    input[type="file"] {
        max-width: 100%;
    }
    .layout .modal_content{
        background: #d3d3d3;
        max-width: calc(100% - 46px);
        padding: 10px;
     }
    .layout .modal .item{
        width: 44%;
    }
    .layout .modal_content .bg{
        height: 110px;
    }
    .layout .col_lg .frame{
        height: 250px;
        width: 100%;
        margin: 5px;
    }
    .layout .col_lg{
        margin: 0 10px;
    }
    .layout .col_sm{
        width: 40%;
        margin: 0 10px;
    }
    .layout .col_sm .frame{
        height: 105px;
    }
    
}
